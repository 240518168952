<template>
  <div class="vh-100 overflow-hidden">
    <!-- Header -->
    <div class="header bg-gradient-danger py-lg-5 log--header">
      <div class="separator separator-bottom separator-skew zindex-100 fill-default">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div
      class="sign--container position-absolute vw-100 vh-100 d-flex justify-content-around align-items-center overflow-auto"
    >
      <div class="d-flex flex-column justify-content-around align-items-center">
        <div class="mt-2 d-flex flex-column justify-content-center align-items-center">
          <div class="d-flex align-items-center mb-2">
            <img src="/img/brand/wring.png" width="60" />
            <p class="my-4 ml-2 px-2 py-1 bg-primary d-inline-block rounded">
              <span class="text-white font-weight-bold h6 text-uppercase">Quikly</span>
            </p>
          </div>
          <div class="text-center">
            <p class="text-white h2 text-capitalize font-weight-bold">
              Intelligent Product Management
            </p>
            <p class="h4 mt--2 text-secondary font-italic font-weight-light">
              For smart automation behavior
            </p>
          </div>
        </div>
        <div class="mt-3 mb-5 py-3 bg-secondary rounded sign--form" v-loading="loading">
          <div class="bg-transparent pb-3">
            <div class="text-muted text-center mt-1 mb-2">
              <small>Sign in with</small>
            </div>
            <div data-netlify-identity-menu></div>
            <div class="btn-wrapper text-center">
              <a :href="githubUrl" class="btn btn-neutral btn-icon">
                <span class="btn-inner--icon"
                  ><img src="/img/icons/common/github.svg"
                /></span>
                <span class="btn-inner--text text-dark">Github</span>
              </a>
              <a :href="googleUrl" class="btn btn-neutral btn-icon">
                <span class="btn-inner--icon"
                  ><img src="/img/icons/common/google.svg"
                /></span>
                <span class="btn-inner--text text-primary">Google</span>
              </a>
            </div>
          </div>
          <div class="px-lg-5">
            <div class="text-center text-muted mb-4">
              <small>Or sign up with credentials</small>
            </div>
            <form role="form" @submit.prevent="handleSubmit()">
              <div class="row">
                <div class="col-md-12 mb-2">
                  <base-input
                    alternative
                    class="mb-3"
                    prepend-icon="ni ni-hat-3"
                    placeholder="Name"
                    name="Name"
                    v-model="name"
                  >
                  </base-input>
                  <validation-error :errors="apiValidationErrors.name" />
                </div>
                <div class="col-md-12 mb-2">
                  <base-input
                    alternative
                    class="mb-3"
                    prepend-icon="ni ni-email-83"
                    placeholder="Email"
                    name="Email"
                    v-model="email"
                  >
                  </base-input>
                  <validation-error :errors="apiValidationErrors.email" />
                </div>
                <div class="col-md-12 mb-2">
                  <base-input
                    alternative
                    class="mb-3"
                    prepend-icon="ni ni-lock-circle-open"
                    placeholder="Password"
                    type="password"
                    name="Password"
                    v-model="password"
                  >
                  </base-input>
                  <password
                    v-model="password"
                    :strength-meter-only="true"
                    @score="showScore"
                    :showStrengthMeter="false"
                  />

                  <validation-error :errors="apiValidationErrors.password" />
                </div>
                <div class="col-md-12 mb-2">
                  <base-input
                    alternative
                    prepend-icon="ni ni-lock-circle-open"
                    placeholder="Confirm Password"
                    type="password"
                    name="Password confirmation"
                    v-model="password_confirmation"
                  >
                  </base-input>
                  <validation-error :errors="apiValidationErrors.password_confirmation" />
                </div>
              </div>
              <div class="text-muted mt--4">
                <small class="h5 text-muted font-weight-bold">
                  password strength:
                  <template v-if="score === 0">
                    <span class="text-danger font-weight-700"> very weak </span>
                  </template>

                  <template v-if="score === 1">
                    <span class="text-danger font-weight-700"> weak </span>
                  </template>

                  <template v-if="score === 2">
                    <span class="text-warning font-weight-700"> medium </span>
                  </template>

                  <template v-if="score === 3">
                    <span class="text-success font-weight-700"> strong </span>
                  </template>

                  <template v-if="score === 4">
                    <span class="text-success font-weight-700"> very strong </span>
                  </template>
                </small>
              </div>
              <div class="row my-2">
                <div class="col-12">
                  <base-input
                    :rules="{
                      required: { allowFalse: false },
                    }"
                    name="Privacy"
                    Policy
                  >
                    <base-checkbox v-model="boolean">
                      <span class="text-muted"
                        >I agree with the
                        <a href="/service-agreement" target="_blank"
                          >Terms and conditions</a
                        ></span
                      >
                    </base-checkbox>
                  </base-input>
                </div>
              </div>
              <div class="text-center">
                <base-button type="primary" native-type="submit" class="mb-3"
                  >Create account</base-button
                >
              </div>
              <div class="text-center text-muted my-1">
                <small>Have an account? <a href="/login">Log in</a> </small>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import Password from "vue-password-strength-meter";

export default {
  components: {
    ValidationError,
    Password,
  },
  mixins: [formMixin],
  data() {
    return {
      googleUrl: process.env.VUE_APP_API_URL_PREFIX + "/auth/v1/oauth2/" + "google",
      githubUrl: process.env.VUE_APP_API_URL_PREFIX + "/auth/v1/oauth2/" + "github",
      name: null,
      boolean: false,
      email: null,
      password: null,
      password_confirmation: null,
      score: "",
      loading: false,
    };
  },

  methods: {
    showScore(score) {
      this.score = score;
    },
    async handleSubmit() {
      if (!this.boolean) {
        await this.$notify({
          type: "danger",
          message: "You need to agree with our terms and conditions.",
        });
        return;
      }
      const user = {
        fullName: this.name,
        email: this.email,
        password: this.password,
      };
      const requestOptions = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };
      if (!this.name) {
        this.$notify({
          type: "danger",
          message: "Please enter a name",
        });
      } else if (!this.email) {
        this.$notify({
          type: "danger",
          message: "Please enter an email",
        });
      } else if (user.password.length < 8) {
        this.$notify({
          type: "danger",
          message: "Please use a password containing at least 8 characters",
        });
      } else if (user.password != this.password_confirmation) {
        this.$notify({
          type: "danger",
          message: "Password and password confirmation are not identical",
        });
      } else {
        try {
          this.loading = true;
          await this.$store
            .dispatch("register", { user, requestOptions })
            .then((res) => {
              if (res === "code 400") {
                this.$notify({
                  type: "danger",
                  message:
                    "Your password is on the list of the most common passwords and is vulnerable to guessing.Your password cannot be all numbers.",
                });
              } else {
                this.$notify({
                  type: "success",
                  message: "Successfully registered.",
                });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } catch (error) {
          this.$notify({
            type: "danger",
            message: error.response.data.message,
          });
          this.setApiValidation(error.response.data.errors);
        } finally {
          this.loading = false;
        }
      }
    },
  },
};
</script>
